import React from "react"
import { graphql, StaticQuery } from "gatsby"

import "./layout.css"

const SuccessPage = () => {
  return (
    <>
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Success!</h2>
          <p style={{ textAlign: "center" }}>
            We will be reviewing your message soon!
          </p>
        </div>
      </article>
    </>
  )
}

export default SuccessPage
